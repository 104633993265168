import './UpdateOrganizationPlan.css';
import { SectionWrapper, MainWrapper, GroupWrapper } from '../../components/Wrappers';
import { Field, FormActions, Permissions } from '../../components/Tools';
import useOrganizationPlan from './UpdateOrganizationPlan.hooks';
import ReactTooltip from 'react-tooltip';
import { FullPageLoader } from '../../components/Loader';
import HomeButton from '../../components/HomeButton';

export default function UpdateOrganizationPlan() {
  const { states, setters, handlers } = useOrganizationPlan();

  const toolTip0 = ' 0 for unlimited';
  const toolTip = ' -1 for unlimited';

  if (states.isLoading) {
    return <FullPageLoader />;
  }

  if (!states.state || states.error) {
    return (
      <>
        <div
          // to={`${API_URL}/users`}
          onClick={() => window.open('https://backoffice.kaspr.io/users', '_self')}
          className="backToHome"
          style={{ position: 'absolute', zIndex: 1001 }}
        >
          <img src="/assets/images/homeIcon.png" alt="back to home" />
        </div>
        <MainWrapper onSubmit={() => null} title="Update organization plan" styleId="main">
          <div className={'message-page'} style={{ background: 'transparent' }}>
            <div className={'message-text'}>
              <p style={{ color: 'darkred' }}>Some Error has occurred</p>
              <span style={{ color: 'darkred', opacity: 0.6 }}>
                {states.error ? states.error.message : 'Make sure the organization plan exists'}
              </span>
            </div>
          </div>
        </MainWrapper>
      </>
    );
  }

  return (
    <>
      <HomeButton />
      <MainWrapper onSubmit={handlers.onSubmit} title="Update organization plan" styleId="main">
        <SectionWrapper title={'Plan settings'}>
          <GroupWrapper>
            <Field
              title={'Plan id'}
              id={'planId'}
              value={states.state.planId}
              disabled
              onChange={handlers.onChange}
            />
            <Field
              title={'Name'}
              id={'name'}
              value={states.state.name}
              onChange={handlers.onChange}
            />
          </GroupWrapper>
          <GroupWrapper>
            <Field
              type={'number'}
              title={'Number of workflows'}
              id={'numberOfWorkflow'}
              toolTip={toolTip}
              min={-1}
              value={states.state.numberOfWorkflow}
              onChange={handlers.onChange}
            />
            <Field
              type={'number'}
              title={'Number of sequences'}
              id={'numberOfSequences'}
              toolTip={toolTip}
              min={-1}
              value={states.state.numberOfSequences}
              onChange={handlers.onChange}
            />
            <Field
              type={'number'}
              title={'Workflow launches'}
              id={'numberOfWorkflowLaunches'}
              value={states.state.numberOfWorkflowLaunches}
              onChange={handlers.onChange}
            />
            <Field
              type={'number'}
              title={'Contact per launch'}
              id={'contactPerLaunch'}
              value={states.state.contactPerLaunch}
              onChange={handlers.onChange}
            />
          </GroupWrapper>
          <GroupWrapper>
            <Field
              type={'number'}
              title={'Work Email Credits per seat'}
              id={'creditsPerSeat'}
              creditTypes="workEmailCredits"
              value={states.state.workEmailCredits.perSeat}
              onChange={handlers.onChange}
            />
            <Field
              type={'number'}
              title={'Work Email Credits Extra'}
              id={'creditsExtra'}
              creditTypes="workEmailCredits"
              value={states.state.workEmailCredits.extra}
              onChange={handlers.onChange}
              disabled={!states.offlineUser}
            />
            <Field
              type={'number'}
              title={'Personal Email Credits per seat'}
              id={'creditsPerSeat'}
              creditTypes="personalEmailCredits"
              value={states.state.personalEmailCredits.perSeat}
              onChange={handlers.onChange}
            />
            <Field
              type={'number'}
              title={'Personal Email Credits Extra'}
              id={'creditsExtra'}
              creditTypes="personalEmailCredits"
              value={states.state.personalEmailCredits.extra}
              onChange={handlers.onChange}
              disabled={!states.offlineUser}
            />
          </GroupWrapper>
          <GroupWrapper>
            <Field
              type={'number'}
              title={'Phone Credits per seat'}
              id={'creditsPerSeat'}
              creditTypes="phoneCredits"
              value={states.state.phoneCredits.perSeat}
              onChange={handlers.onChange}
            />
            <Field
              type={'number'}
              title={'Phone Credits Extra'}
              id={'creditsExtra'}
              creditTypes="phoneCredits"
              value={states.state.phoneCredits.extra}
              onChange={handlers.onChange}
              disabled={!states.offlineUser}
            />
            <Field
              type={'number'}
              title={'Export Credits per seat'}
              id={'creditsPerSeat'}
              creditTypes="exportCredits"
              value={states.state.exportCredits.perSeat}
              onChange={handlers.onChange}
            />
            <Field
              type={'number'}
              title={'Export Credits Extra'}
              id={'creditsExtra'}
              creditTypes="exportCredits"
              value={states.state.exportCredits.extra}
              onChange={handlers.onChange}
              disabled={!states.offlineUser}
            />
          </GroupWrapper>
          <GroupWrapper>
            <Field
              type={'number'}
              title={'Price per seat'}
              id={'pricePerSeat'}
              value={states.state.pricePerSeat}
              onChange={handlers.onChange}
            />
            <Field
              type={'number'}
              title={'Number of seats'}
              id={'numberOfSeats'}
              value={states.state.numberOfSeats}
              onChange={handlers.onChange}
            />
            <Field
              type={'number'}
              title={'Number of months'}
              id={'numberOfMonths'}
              value={states.state.numberOfMonths}
              onChange={handlers.onChange}
              disabled={!states.offlineUser}
            />
          </GroupWrapper>
          <GroupWrapper>
            <Field
              type={'number'}
              title={'Min seats'}
              id={'minSeats'}
              value={states.state.minSeats}
              onChange={handlers.onChange}
            />
            <Field
              type={'number'}
              title={'Max seats'}
              id={'maxSeats'}
              value={states.state.maxSeats}
              onChange={handlers.onChange}
            />
            <Field
              type={'number'}
              title={'Max lists'}
              id={'maxLists'}
              value={states.state.maxLists}
              onChange={handlers.onChange}
              toolTip={toolTip}
              min={-1}
            />
            <Field
              type={'number'}
              title={'Max tags'}
              id={'maxTags'}
              value={states.state.maxTags}
              onChange={handlers.onChange}
              toolTip={toolTip}
              min={-1}
            />
          </GroupWrapper>
          <GroupWrapper>
            <Field
              type={'number'}
              title={'Max admins'}
              id={'maxAdmins'}
              value={states.state.maxAdmins}
              onChange={handlers.onChange}
            />
            <Field
              type={'number'}
              title={'Bulk per month'}
              id="bulkEnrichmentLimits"
              bulkTypes="perMonth"
              value={states.state.bulkEnrichmentLimits?.perMonth}
              onChange={handlers.onChange}
            />
            <Field
              type={'number'}
              title={'Contacts per bulk'}
              id="bulkEnrichmentLimits"
              bulkTypes="perLaunch"
              value={states.state.bulkEnrichmentLimits?.perLaunch}
              onChange={handlers.onChange}
            />
          </GroupWrapper>
        </SectionWrapper>
        <SectionWrapper title={'API limits'}>
          <GroupWrapper>
            <Field
              type={'number'}
              title={'Days'}
              id={'day'}
              value={states.state.publicApiLimits.day}
              onChange={handlers.onChange}
              toolTip={toolTip0}
            />
            <Field
              type={'number'}
              title={'Hours'}
              id={'hour'}
              value={states.state.publicApiLimits.hour}
              onChange={handlers.onChange}
              toolTip={toolTip0}
            />
            <Field
              type={'number'}
              title={'Minutes'}
              id={'minute'}
              value={states.state.publicApiLimits.minute}
              onChange={handlers.onChange}
              toolTip={toolTip0}
            />
          </GroupWrapper>
        </SectionWrapper>
        <SectionWrapper className={'form-line'}>
          <input
            type="checkbox"
            id={'isCumulative'}
            onChange={(e) => handlers.onChange(e.target.checked, 'isCumulative')}
            checked={states.state.isCumulative}
            style={{ cursor: 'pointer' }}
          />
          <label htmlFor="isCumulative" style={{ cursor: 'pointer' }}>
            Is cumulative
          </label>
        </SectionWrapper>
        <SectionWrapper title={'Plan permissions'} className={'permissions-wrapper'}>
          <Permissions
            permissionsWithActions={states.permissionsWithActions}
            setPermissionsWithActions={setters.setPermissionsWithActions}
            reset={states.reset}
          />
        </SectionWrapper>
        {states.permissionsWithoutActions.map((p) => {
          const mappingNames = [
            { codeName: 'PublicApi', displayName: 'Public Api' },
            {
              codeName: 'HideGenericsEmails',
              displayName: 'Hide Generic Emails',
            },
          ];
          return (
            <SectionWrapper className={'form-line'} key={p.name}>
              <input
                type="checkbox"
                id={p.name}
                style={{ cursor: 'pointer' }}
                onChange={() => {
                  setters.setPermissionsWithoutActions(
                    states.permissionsWithoutActions.map((pe) => {
                      if (pe.name === p.name) return { ...pe, checked: !pe.checked };
                      return { ...pe };
                    }),
                  );
                }}
                checked={p.checked}
              />
              <label htmlFor={p.name} style={{ cursor: 'pointer' }}>
                {mappingNames.map((m) => {
                  if (m.codeName === p.name) {
                    return m.displayName;
                  }
                  return null;
                })}
              </label>
            </SectionWrapper>
          );
        })}
        <FormActions onReset={handlers.onResetState} onSubmit={handlers.onSubmit} />
        <ReactTooltip effect="solid" offset={{ bottom: 7 }} />
      </MainWrapper>
    </>
  );
}
