export default function HomeButton() {
  return (
    <div
      onClick={() => window.open('https://backoffice.kaspr.io/users', '_self')}
      style={{ position: 'relative', width: 60, height: 60 }}
    >
      {/* <Link to={`${API_URL}/users`}> */}
      <img
        src="/assets/images/homeIcon.png"
        alt="back to home"
        style={{
          cursor: 'pointer',
          display: 'inherit',
          position: 'absolute',
          zIndex: 1001,
        }}
      />
      {/* </Link> */}
    </div>
  );
}
